
import { Component, Prop, Vue } from "vue-property-decorator";
import Quill, { QuillOptionsStatic } from "quill";
import { htmlEditButton } from "quill-html-edit-button";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";

@Component
export default class Wysiwyg extends Vue {
  /**
   * @Prop {string} id
   * quill editor will mount on this element
   * must be unique for every instance of this component
   */
  @Prop() id!: string;
  @Prop() content!: string;
  protected quillOptions: QuillOptionsStatic = {
    debug: "warn",
    modules: {
      toolbar: [
        { size: ["small", false, "large", "huge"] },
        "bold",
        "italic",
        "underline",
        "link",
        "code"
      ]
    },
    theme: "snow"
  };
  protected quill!: Quill;

  mounted() {
    this.$nextTick(() => {
      this.quill = new Quill("#" + this.id, this.quillOptions);
      this.setDefaults();
      this.addEditorListener();
    });
  }

  beforeDestroy() {
    this.quill.off("text-change", (): void => {
      return;
    });
  }

  protected setDefaults(): void {
    const delta = this.quill.clipboard.convert(
      this.content as
        | { html?: string | undefined; text?: string | undefined }
        | undefined
    );

    this.quill.setContents(delta, "api");
  }

  protected addEditorListener(): void {
    this.quill.on("text-change", this.onEditorChange);
  }

  public onEditorChange(): void {
    const quillDelta: any = this.quill.getContents();
    const quillToHtml = new QuillDeltaToHtmlConverter(quillDelta.ops, {});
    const html = quillToHtml.convert();
    this.$emit("sendUpdates", this.id, html);
  }
}
